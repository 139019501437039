<template>
  <div class="">
    <header-page :header="headers"></header-page>
    <v-container>
      <v-row>
        <v-col v-if="loaderdata === false" cols="12" md="11" class="mx-auto" >
          <v-card tile flat>
            <v-card-title>
              <v-icon @click="backToPrevious" class="mr-10" color="secondary">{{ icons.mdiKeyboardBackspace }}</v-icon>
              <v-spacer></v-spacer>
              <v-card-subtitle class="text-md pa-0">
                <v-icon size="13" class="secondary--text mr-1">{{ icons.mdiAlertCircle }}</v-icon><span
                  class="secondary--text">Published at: {{ reoi.created_at }}</span>
              </v-card-subtitle>
            </v-card-title>
            <v-card-title>
              <span>{{ reoi.title }}</span>
            </v-card-title>

            <v-card-subtitle>
              <div>
                <v-icon small class="mr-1">{{ icons.mdiTargetAccount }}</v-icon>
                <span>For: #</span>
                <!-- <span> {{ ext_proposals.type }}</span> -->
              </div>
              <div>
                <v-icon small class="mr-1">{{ icons.mdiScatterPlot }}</v-icon>
                <span>Domain: #</span>
                <!-- <span>{{ ext_proposals.domain }}</span> -->
              </div>
              <div>
                <v-icon small class="mr-1">{{ icons.mdiMapMarkerOutline }}</v-icon>
                <span>Location: #</span>
                <!-- <span>{{ ext_proposals.location }}</span> -->
              </div>
            </v-card-subtitle>

            <v-card-text class="d-flex align-centr">
              <v-avatar size="40">
                <v-img height="5rem" :src="require('@/assets/images/avatars/profil-sec.svg')" contain
                  alt="Connect and socialize">
                </v-img>
              </v-avatar>
              <div class="mx-4 d-bloc" v-if="reoi.enterprise">
                <h3>
                  <span></span>
                  <span class="secondary--text">
                    {{ reoi.enterprise.name }}
                  </span>
                </h3>
                <!-- <a :href="reoi.enterprise.website" target="_blank">{{ reoi.enterprise.website }}</a> -->

              </div>
              <v-spacer></v-spacer>
              <v-icon size="13" class="error--text mr-1">{{ icons.mdiCloseCircle }}</v-icon>
              <span class="error--text">Expiring on: {{ reoi.created_at }}</span>
              <!-- <span class="error--text">Expiring on: {{ reoi.expire_at }}</span> -->
            </v-card-text>
            
            <v-card-text>
              <v-divider></v-divider>
            </v-card-text>
            <v-card-actions>
              <!-- <div class="d-flex py-4" v-if="submit === true"> -->
              <v-spacer></v-spacer>
              <check-role-dialog
                :actions="{ type: reoi.enterprise.type.libele, actionText: 'Reply proposal', link: 'proposal.reply', role: 'rfp_reply', title: reoi.title, k: reoi.id }">
              </check-role-dialog>
              <!-- </div> -->
            </v-card-actions>
          </v-card>
          <v-card tile class="mt-4">
            <v-card-text cols="12" class="">
              <v-divider class="mt-4 mb-6"></v-divider>
              <v-card elevation="0" tile>
                <v-card-title class="subaccent">{{ reoi.object }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text v-html="reoi.content">
                  <!-- {{ reoi.content }} -->
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-text cols="12">
              <v-card-subtitle class="subaccent">
              </v-card-subtitle>
            </v-card-text>
          </v-card>

          <v-card tile class="mt-4 ">
          <detail-about-company :company="reoi.enterprise"></detail-about-company></v-card>

        </v-col>
        <v-col cols="12" md="11" class="mx-auto" v-else>
          <v-skeleton-loader v-bind="attrs" type="card-avatar, article, actions"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// mdiVideoInputComponent
import { mdiAlertCircle, mdiDomain, mdiTargetAccount, mdiCloseCircle, mdiCalendar, mdiKeyboardBackspace, mdiScatterPlot, mdiAlertOutline, mdiMapMarkerOutline, mdiOpenInNew } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { Utils } from '@/plugins/Utils';
import CheckRoleDialog from '@/views/components/CheckRoleDialog.vue';
import HeaderPage from '@/views/pages/components/HeaderPage.vue'
import DetailAboutCompany from "@/views/components/company/DetailAboutCompany.vue"


export default {
  components: {
    CheckRoleDialog,
    HeaderPage,
    DetailAboutCompany
  },
  data() {
    return {
      loaderdata: false,
      submit: true,
      absolute: true,
      overlay: false,
      reoi: {},
      proposalRecList: [],
      headers: {
        titlepage: '',
        icon: this.icons.mdiDomain
      },

      loadingRfpEnterprise: false,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  computed: {
    length() {
      return 7000
    },
  },
  methods: {
    backToPrevious() {
      this.$router.go(-1)
    },
    loadInitialProposals() {

      this.loaderdata = true
      Drequest.api(`lazyloading.rfp?dfilters=on&id:eq=${this.$route.params.offer_id}&next=1&per_page=1`)
        .get((response) => {
          if (response.success === true) {
            this.reoi = response.listEntity[0]
            this.headers.titlepage = response.listEntity[0].requestype.designation
            this.loaderdata = false
          }
          else {
            this.loaderdata = false
          }
        })
        .catch((err) => {
          this.loaderdata = false
          this.loaderdata = false
        });
    }
  },
  beforeMount() {
    this.loadInitialProposals()
  },
  setup(props) {
    const icons = { mdiAlertCircle, mdiDomain, mdiTargetAccount, mdiCloseCircle, mdiCalendar, mdiKeyboardBackspace, mdiScatterPlot, mdiAlertOutline, mdiMapMarkerOutline, mdiOpenInNew }
    return {
      icons
    }
  }
}
</script>